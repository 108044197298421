import getEnvironment from "./getEnvironment";

export default function () {
  let styleSrc = "";

  const environment = getEnvironment();
  if (environment === "local") {
    styleSrc = "https://custom-code-local.astro.tax/webflowStyles.css";
  } else if (environment === "develop") {
    styleSrc = "https://custom-code-develop.astro.tax/webflowStyles.css";
  } else {
    styleSrc = "https://custom-code.astro.tax/webflowStyles.css";
  }
  document.head.insertAdjacentHTML(
    "beforeend",
    `<link rel="stylesheet" type="text/css" href=${styleSrc}>`
  );
}