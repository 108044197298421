window.loadPaperform = function (paperformId) {
  var gclidValue = localStorage.getItem("gclid") || "";
  var acaValue = localStorage.getItem("aca") || "";
  var achValue = localStorage.getItem("ach") || "";
  var div = document.createElement("div");
  div.setAttribute("prefill", `gclid=${gclidValue}&aca=${acaValue}&ach=${achValue}`);
  div.setAttribute("data-paperform-id", paperformId);
  div.setAttribute("scroll-offset", "150");

  // Append the newly created div to the specific container div
  var container = document.getElementById("paperformContainer");
  if (container) {
    container.appendChild(div);
  } else {
    console.error("Container not found");
    throw new Error("could not locate container to substitute gclid");
  }

  var embedScript = document.createElement("script");
  embedScript.src = "https://paperform.co/__embed.min.js";
  document.body.appendChild(embedScript);
};
