export default function saveGclidToLocalStorage() {
  const existingGclid = localStorage.getItem("gclid");
  if (existingGclid) {
    // if glic already in local storage, we do not replace it
    return;
  }

  // check if gclid is in the url
  const urlParams = new URLSearchParams(window.location.search);
  const gclid = urlParams.get("gclid");
  if (gclid) {
    localStorage.setItem("gclid", gclid);
  }
}
