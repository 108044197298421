export default function saveAcaAndAchToLocalStorage() {
  // check if gclid is in the url
  const urlParams = new URLSearchParams(window.location.search);
  const aca = urlParams.get("aca");
  const ach = urlParams.get("ach");
  if (aca) {
    localStorage.setItem("aca", aca);
  }
  if (ach) {
    localStorage.setItem("ach", ach);
  }
}
