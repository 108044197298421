const {
  default: saveGclidToLocalStorage,
} = require("./helpers/saveGclidToLocalStorage");
const { default: addStyles } = require("./helpers/addStyles");
//! do not remove this import. It is not used in this file but it is referenced directly in webflow
const { default: loadPaperform } = require("./helpers/loadPaperform");
import * as Sentry from "@sentry/browser";
import getEnvironment from "./helpers/getEnvironment";
import saveAcaAndAchToLocalStorage from "./helpers/saveAcaAndAchToLocalStorage";

const environment = getEnvironment();
Sentry.init({
  dsn: "https://0ddf6a8b1b57479faca3cc0595577a55@o468354.ingest.sentry.io/4505113071190016",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: environment,
});

if (environment === "thisCodeWillNeverExecute") {
  // we add the paperform functio here so that it is referenced somewhere in VSC
  // by doing this we avoid it being accidentally removed by the code cleanup
  loadPaperform("");
}

// indication to see if the webflow server is connected
console.log("astro-custom-code body script connected!");

// get the current path. Based on this path we execute different functions
const currentPath = window.location.pathname;

// code to run on all pages
saveGclidToLocalStorage();
saveAcaAndAchToLocalStorage();
addStyles();

if (currentPath === "/") {
  // add code to run on the homepage
}

if (currentPath === "/some-path") {
  // add code to run on a specific path
}
